<template>
  <div v-if="loading" class="preview-modal__loading">
    <FileIconComponent name="loading" />
  </div>
  <ProductComponent v-else :data="product" is-modal @close="$emit('close')" />
</template>

<script>
import ProductComponent from "components/product/ProductComponent.vue";
import PRODUCT from "@/graphql/queries/product.graphql";
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "ProductPreviewModalComponent",
  props: {
    data: Object,
  },
  data() {
    return {
      product: null,
      loading: false,
    };
  },
  created() {
    this.loading = true;
    this.$apollo.provider.defaultClient
      .query({
        query: PRODUCT,
        variables: {
          id: this.data.id,
        },
      })
      .then(({ data }) => {
        this.loading = false;
        this.product = data.product;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  components: { FileIconComponent, ProductComponent },
};
</script>

<style lang="stylus">
.preview-modal {
  &__loading {
    display flex
    align-items center
    height 80vh
    width 8vw

    .icon {
      width 100%
    }
  }
}
</style>
