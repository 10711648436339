var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"product-card",class:{ 'product-card--small': _vm.small, 'product-card--wide': _vm.wide, 'product-card--tall': _vm.tall }},[_c('div',{staticClass:"product-card__top"},[_c('div',{staticClass:"product-card__top-col"},[(_vm.data.join_share && _vm.data.join_share.share)?_c('span',{staticClass:"product-card__discount"},[_vm._v("\n        "+_vm._s(_vm.data.join_share.share.percent)+" %\n      ")]):_vm._e(),(_vm.data.in_wholesale)?_c('span',{staticClass:"product-card__wholesale"},[_vm._v(" Оптом ")]):_vm._e(),(_vm.data.in_wholesale)?_c('span',{staticClass:"product-card__retail"},[_vm._v(" Розница ")]):_vm._e()]),_c('div',{staticClass:"product-card__top-col product-card__top-col--right"},[_c('button',{staticClass:"product-card__favorite",class:{ 'product-card__favorite--active': _vm.inFavorites },attrs:{"type":"button","title":"В избранное"},on:{"click":function($event){$event.preventDefault();return _vm.addToFavorites.apply(null, arguments)}}},[(_vm.loading)?_c('FileIconComponent',{attrs:{"name":"loading"}}):_c('RemixIconComponent',{attrs:{"category":"Health","name":"heart-3-line"}})],1)])]),_c('div',{staticClass:"product-card__image-container"},[_c('ImgComponent',{staticClass:"product-card__image",attrs:{"head_img":_vm.data_img,"width":750,"height":1000}}),_c('span',{staticClass:"product-card__image-hover"},[(_vm.data.title)?_c('router-link',{attrs:{"to":{
          name: 'product',
          params: { id: _vm.data.parent_id ? _vm.data.parent_id : _vm.data.id, link: _vm.data.link },
        }}}):_vm._e(),_c('button',{staticClass:"btn btn--sm btn--white",attrs:{"type":"button"},on:{"click":_vm.showPreviewModal}},[_c('RemixIconComponent',{attrs:{"category":"System","name":"eye-line"}}),_c('span',[_vm._v("Быстрый просмотр")])],1)],1)],1),_c('div',{staticClass:"product-card__info"},[_c('div',{staticClass:"product-card__content"},[(_vm.data.reviews)?_c('div',{staticClass:"product-card__reviews"},[_c('div',{staticClass:"product-card__reviews-stars"},_vm._l((5),function(i){return _c('RemixIconComponent',{key:i,staticClass:"product-card__reviews-star",class:{ 'product-card__reviews-star--filled': _vm.averageReviewsMark >= i },attrs:{"category":"System","name":"star-fill"}})}),1),_c('span',{staticClass:"product-card__reviews-count"},[_vm._v("("+_vm._s(_vm.data.reviews.length)+")")])]):_vm._e(),_c('div',{staticClass:"product-card__title"},[(_vm.data.brand && _vm.data.brand.title)?_c('router-link',{staticClass:"product-card__company",attrs:{"to":{ name: 'catalog', query: { brand_id: _vm.data.brand.id, page_title: _vm.data.brand.title } }}},[_vm._v("\n          "+_vm._s(_vm.data.brand.title)+"\n        ")]):_vm._e(),(_vm.data.brand && _vm.data.brand.title)?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.data.title)?_c('router-link',{attrs:{"to":{
            name: 'product',
            params: { id: _vm.data.parent_id ? _vm.data.parent_id : _vm.data.id, link: _vm.data.link },
          }}},[_vm._v("\n          "+_vm._s(_vm.data.title)+"\n        ")]):_vm._e()],1)]),_c('div',{staticClass:"product-card__bottom"},[(_vm.data.price)?_c('router-link',{staticClass:"product-card__prices",attrs:{"to":{
          name: 'product',
          params: {
            id: _vm.data.parent_id ? _vm.data.parent_id : _vm.data.id,
            link: _vm.data.link || (_vm.data.parent && _vm.data.parent.link),
          },
        }}},[_c('span',{staticClass:"product-card__price"},[_vm._v("\n          "+_vm._s(_vm._f("price_from_dollar")(_vm._f("share_price")(_vm.data.price,_vm.data),_vm.$store.state))+"\n        ")]),(_vm.data.join_share && _vm.data.join_share.share)?_c('span',{staticClass:"product-card__old-price"},[_vm._v("\n          "+_vm._s(_vm._f("price_from_dollar")(_vm.data.price,_vm.$store.state))+"\n        ")]):_vm._e()]):_vm._e(),_c('button',{staticClass:"btn btn--main btn--sm",attrs:{"type":"button"},on:{"click":_vm.showPreviewModal}},[_c('RemixIconComponent',{attrs:{"category":"Finance","name":"shopping-cart-2-line"}}),_c('span',[_vm._v("Купить")])],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }