<template>
  <div v-show="swiper" class="home-stories">
    <button
      type="button"
      title="Вверх"
      class="home-stories__btn"
      @click="swiper.slidePrev()"
      :disabled="swiper?.isBeginning"
      v-if="stories.length > 5"
    >
      <RemixIconComponent category="System" name="arrow-up-s-line" class="home-stories__btn-icon" />
    </button>
    <div class="home-stories__slides">
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <template v-if="streams && streams.length">
            <div class="swiper-slide" v-for="(item, i) in streams" :key="i">
              <div @click="tryToGoStream(item)">
                <HomeStreamsComponent :stream="item" />
              </div>
            </div>
          </template>
          <template v-if="storiesByShops && storiesByShops.length">
            <div class="swiper-slide" v-for="(item, i) in storiesByShops" :key="i">
              <HomeStoryComponent :story="item.list[0]" :stories="item.list" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <button
      type="button"
      title="Вниз"
      class="home-stories__btn"
      @click="swiper.slideNext()"
      :disabled="swiper?.isEnd"
      v-if="stories.length > 5"
    >
      <RemixIconComponent category="System" name="arrow-down-s-line" class="home-stories__btn-icon" />
    </button>
  </div>
</template>

<script>
// import SliderComponent from "components/SliderComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import HomeStreamsComponent from "views/home/components/HomeStreamsComponent.vue";
import HomeStoryComponent from "views/home/components/HomeStoryComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import StoriesModal from "components/modals/components/StoriesModal.vue";

export default {
  name: "HomeStoriesComponent",
  props: {
    stories: {
      type: Array,
      default: () => [],
    },
    streams: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    storiesByShops() {
      return this.stories.reduce((arr, item) => {
        const index = arr.findIndex((i) => i.shop?.id === item.shop?.id);
        if (index === -1) {
          arr.push({
            shop: item.shop,
            list: [item],
          });
        } else {
          arr[index].list.push(item);
        }
        return arr;
      }, []);
    },
  },
  methods: {
    tryToGoStream(item) {
      if (this.$store.state.auth.user) {
        this.$router.push({
          name: "stream",
          params: { link: item.link },
          query: { streamId: item.id, autoplay: true },
        });
      } else {
        this.openLoginModal();
      }
    },
    openLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
      });
    },
    openStreamModal(item) {
      console.log(item);
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        direction: "horizontal",
        mousewheel: true,
        slidesPerView: "auto",
        spaceBetween: 20,
        breakpoints: {
          1575: {
            slidesPerView: 5,
            direction: "vertical",
          },
        },
      });
    });
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  components: {
    HomeStreamsComponent,
    RemixIconComponent,
    HomeStoryComponent,
  },
};
</script>

<style lang="stylus">
.home-stories {
  display flex
  align-items center
  justify-content center
  gap 20px
  height 78px
  max-height 78px
  padding-left 15px
  background var(--blue-50)
  z-index 1
	user-select: none
  +above(1576px) {
    padding 20px
    border-radius: 0 50px 50px 0;
    box-shadow: 0 4px 10px 0 #bfbebe;
    border: 2px solid #fcfdff;
    flex-direction column
    position absolute
    left 0
		z-index: 3
    height 650px
    max-height 650px
    //padding-left 0
  }

  &__slides {
    position relative
    height 100%
  }

  .swiper {
    width 100%
    height 100%
    max-height 505px
  }

  .swiper-slide {
		+below(1575px) {
			align-items: flex-start
		}

    &:last-child {
      margin-right 0 !important
    }
  }

  //.swiper-wrapper {
  //  flex-direction column
  //}

  &__btn {
    width 100%
    max-width 30px
    height 100%
    max-height 30px
    background none
    border none
    display flex
    align-items center
    justify-content center
    cursor pointer
    transform: rotate(270deg);
    +above(1576px) {
      transform none
    }
    +below(540px) {
      display none
    }

    figure svg {
      max-width 30px
      max-height 30px
    }

    &:disabled {
      cursor not-allowed

      figure svg {
        fill #ACACAC
      }
    }
  }
}
</style>
