<template>
  <div class="site-offers">
    <ul class="site-offers__container">
      <li>
        <RemixIconComponent category="Finance" name="wallet-3-line" />
        <span>Бонусные рубли<br />за покупки</span>
      </li>
      <li>
        <RemixIconComponent category="Map" name="truck-line" />
        <span>Товары разных продавцов<br />одной доставкой</span>
      </li>
      <li>
        <RemixIconComponent category="User" name="user-smile-line" />
        <span>Более 8 000 проверенных<br />продавцов</span>
      </li>
      <li>
        <RemixIconComponent category="Finance" name="hand-coin-line" />
        <span>Выгодные<br />цены</span>
      </li>
    </ul>
  </div>
</template>
<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "SiteOffersComponent",
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.site-offers {
  display flex
  justify-content center
  padding 0 15px

  &__container {
    max-width var(--small_width)
    width 100%
    display flex
    align-items center
    justify-content space-between
    gap 16px
    +below(860px) {
      flex-direction column
      align-items flex-start
      justify-content flex-start
    }

    li {
      display inline-flex
      gap 12px

      .icon {
        background: var(--blue-200);
        border-radius: 86px;
        width 68px
        height 68px

        svg {
          width 24px
          height 24px

          path {
            fill var(--green)
          }
        }
      }

      span {
        font-weight: normal;
        font-size: 0.875em;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--gray-500);
      }
    }
  }
}
</style>
