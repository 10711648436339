<template>
  <div class="home-wrapper" :class="{ 'home-wrapper--white': white }">
    <div class="container">
      <div class="home-wrapper__inner">
        <div class="home-wrapper__header">
          <h3 class="home-wrapper__title">{{ title }}</h3>
          <router-link :to="route" class="home-wrapper__show-all btn btn--sm btn--white">
            {{ buttonTitle }}
          </router-link>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeWrapperComponent",
  props: {
    items: Array,
    title: String,
    route: Object,
    buttonTitle: String,
    white: Boolean,
  },
};
</script>

<style lang="stylus">
.home-wrapper {
	&--white {
		background: var(--white);
		padding: 48px 15px
	}

	&__inner {
		display flex
		flex-direction column
		gap: 24px
	}

	&__header {
		display flex
		align-items center
		gap: 20px
	}

	&__title {
		font-size: 2.25rem
		font-style: normal;
		font-weight: 500;
		line-height: 1.38;
		flex-grow 1
		margin: 0
	}

	&__show-all {
		color: var(--gray-500, #62627A);
		font-size: 1rem
		line-height: 20px;
	}
}
</style>
