<template>
  <div class="default-modal images-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title"></h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="System" name="close-line" />
      </button>
    </div>
    <div class="default-modal__body images-modal__body">
      <ProductGalleryComponent
        :items="images"
        :head_img="head_img"
        :default-alt="defaultAlt"
        :first-active-item="activeItem"
        :is-modal="false"
        :is-images-modal="true"
      />
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "ImagesGalleryModal",
  components: {
    RemixIconComponent,
    ProductGalleryComponent: () => import("components/product/ProductGalleryComponent.vue"),
  },
  props: {
    images: Array,
    head_img: Object,
    defaultAlt: String,
    activeItem: Object,
  },
};
</script>

<style lang="stylus">
.images-modal {
  width 100%
  border-radius 0

  &__body {
    height 100%
  }

  .product-gallery {
    width 100%
    height 100%
    justify-content center
    --btn-width: 50px

    &--controls .product-gallery__btn {
      +above(861px) {
        display flex
      }
    }

    &--controls .product-gallery__image {
      +above(861px) {
        padding 0 var(--btn-width)
      }
    }

    &__image {
      position relative
      height 100%
    }

    &__btn {
      position absolute
      top 50%
      left 0
      z-index 1
      transform: translateY(-50%)
      width: var(--btn-width)
      height: var(--btn-width)

      .icon svg path {
        transition var(--transition)
      }

      &:hover {
        .icon svg path {
          fill var(--accent)
        }
      }

      &-next {
        right 0
        left auto
      }
    }
  }
}
</style>
