<template>
  <div class="home-story" v-if="story">
    <div @click="openStoriesModal" class="home-story__container">
      <template v-if="story.shop && story.shop.head_img">
        <ImgComponent v-if="story.shop.head_img" :head_img="story.shop.head_img" class="home-story__img" />
        <span v-if="story.shop.title" class="home-story__title">{{ story.shop.title }}</span>
      </template>
      <template v-else>
        <ImgComponent src="/static/images/sasahub.webp" class="home-story__img" />
        <span class="home-story__title">SASAHUB</span>
      </template>
    </div>
  </div>
</template>

<script>
import StoriesModal from "components/modals/components/StoriesModal.vue";

export default {
  name: "HomeStoryComponent",
  props: {
    story: {
      type: Object,
      default: () => {},
    },
    stories: Array,
  },
  methods: {
    openStoriesModal() {
      this.$store.state._modals.push({
        component: StoriesModal,
        options: {
          stories: this.stories,
          index: this.stories.findIndex((item) => item.id === this.story.id),
        },
        stories: true,
      });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/textcut.styl"

.home-story {
  &__container {
		max-height: 85px
		display flex
		flex-direction column
		align-items center
		gap 3px
		flex-shrink 0
		cursor pointer
		+above(1576px) {
			gap 10px
		}
	}

	&:hover {
		color: var(--accent)
	}

  &__img {
		flex-shrink: 0
		width: 60px
		height: 60px
		border-radius: 50%
		background: linear-gradient(180deg, var(--accent) 0%, #FFC89F 100%);
		padding: 2px
  }

  &__title {
    font-size 0.75rem
    line-height 15px
		max-width: 80px
		text-align: center
		word-break: break-all;
		textcut(1);
  }
}
</style>
