<template>
  <div v-if="items && items.length" class="home-new-products">
    <ProductCardComponent v-for="item in items" :key="item.id" :data="item" wide />
  </div>
</template>

<script>
import ProductCardComponent from "components/product/ProductCardComponent.vue";

export default {
  name: "NewProductsGridComponent",
  components: { ProductCardComponent },
  props: {
    items: Array,
    title: String,
    route: Object,
    buttonTitle: String,
  },
};
</script>

<style lang="stylus">
.home-new-products {
  justify-content: center
	display: grid
	grid-template-columns: 1fr 1fr
	gap: 20px
}
</style>
