<template>
  <div
    class="param-slider"
    :class="{ 'param-slider--arrows': canShowArrows, 'param-slider--vertical': vertical }"
    v-if="items && items.length > 1"
  >
    <button
      type="button"
      title="Влево"
      v-if="canShowArrows"
      class="param-slider__arrow"
      @click="slidePrev"
      :disabled="swiper && swiper.isBeginning"
    >
      <RemixIconComponent v-if="vertical" category="System" name="arrow-up-line" />
      <RemixIconComponent v-else category="System" name="arrow-left-line" />
    </button>
    <div class="param-slider__items swiper" ref="items">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, i) in items" :key="i">
          <slot :item="item"></slot>
        </div>
      </div>
    </div>
    <button
      type="button"
      title="Вправо"
      v-if="canShowArrows"
      class="param-slider__arrow"
      @click="slideNext"
      :disabled="swiper && swiper.isEnd"
    >
      <RemixIconComponent v-if="vertical" category="System" name="arrow-down-line" />
      <RemixIconComponent v-else category="System" name="arrow-right-line" />
    </button>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "ProductParamSlider",
  props: {
    items: Array,
    sliderOptions: Object,
  },
  data() {
    return {
      direction: null,
      slidesPerView: null,
      swiper: null,
    };
  },
  watch: {
    items() {
      this.init();
    },
  },
  computed: {
    canShowArrows() {
      return this.items.length > (this.slidesPerView || 0);
    },
    vertical() {
      return (this.direction || "horizontal") === "vertical";
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
        this.swiper = new Swiper(this.$refs.items, this.sliderOptions);
        this.swiper.on("breakpoint", (swiper, breakpointParams) => {
          this.updateLocalOptions(breakpointParams);
        });
        this.swiper.emit("breakpoint", this.getCurrentBreakpoint());
      });
    },
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints) {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
    updateLocalOptions(options) {
      this.direction = options.direction;
      this.slidesPerView = options.slidesPerView;
    },
    slideNext() {
      this.swiper.slideNext();
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.swiper-slide {
  width auto
  height: auto;
  display inline-flex
  align-items center
  justify-content center
}

.param-slider {
  width: 100%;
  display flex
  justify-content flex-start
  align-items flex-start
  position relative

  &--arrows:not(&--vertical) {
    padding 0 32px
  }

  &--arrows&--vertical {
    padding 40px 0
  }

  &--vertical &__arrow {
    &:first-child {
      absolute left top right
      bottom unset
    }

    &:last-child {
      absolute right left bottom
      top unset
    }
  }

  &__arrow {
    z-index: 1;
    margin auto
    box-sizing: border-box;
    width 40px
    height 40px
    display flex
    align-items center
    justify-content center
    cursor pointer
    background none
    border none
    padding 0

    &[disabled] {
      cursor default

      .icon svg path {
        fill var(--gray-100)
      }
    }

    &:not([disabled]):hover {
      .icon svg path {
        fill var(--accent)
      }
    }

    .icon {
      width 24px
      height 24px

      svg path {
        fill var(--body-color-muted)
      }
    }

    &:first-child {
      absolute left top bottom
    }

    &:last-child {
      absolute right top bottom
    }
  }

  &__items {
    width 100%
    height 100%
  }
}
</style>
