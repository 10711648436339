<template>
  <div class="slider" :class="{ 'slider--full-width': fullWidth }" v-if="items && items.length">
    <div class="slider__container">
      <div class="slider__header" v-if="title">
        <h3 class="slider__title">{{ title }}</h3>
        <router-link :to="route" class="btn btn--sm btn--white" v-if="buttonTitle">
          <span>{{ buttonTitle }}</span>
        </router-link>
      </div>
      <div class="slider__items">
        <div class="swiper" ref="items">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in items" :key="i">
              <slot :item="item"></slot>
            </div>
          </div>
        </div>
        <SliderArrowsComponent
          v-if="swiper && !hideArrows && canShowArrows"
          @left="swiper.slidePrev()"
          @right="swiper.slideNext()"
          :isBeginning="swiper.isBeginning"
          :isEnd="swiper.isEnd"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";

export default {
  name: "SliderComponent",
  props: {
    title: String,
    buttonTitle: String,
    items: Array,
    hideArrows: Boolean,
    fullWidth: Boolean,
    route: {
      type: Object,
      default() {
        return {
          name: "",
        };
      },
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          slidesPerView: "auto",
          spaceBetween: 24,
          pagination: false,
          clickable: false,
        };
      },
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    canShowArrows() {
      let slidesPerView = this.getCurrentBreakpoint().slidesPerView;
      if (typeof slidesPerView === "string") {
        return slidesPerView === "auto";
      }
      if (typeof slidesPerView === "number") {
        return this.items.length > slidesPerView;
      }
      return false;
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, this.sliderOptions);
    });
  },
  methods: {
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints && typeof window !== "undefined") {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
  },
  components: { SliderArrowsComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.swiper-slide {
  width auto
  max-width 100%
  display inline-flex
}

.slider {
  display flex
  justify-content center
  align-items center
  padding 0 15px
	user-select: none

	&--full-width {
		padding: 0
	}

  &__title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    color: var(--body-color);
  }

  &__items {
    position relative
    width 100%
  }

  &__container {
    max-width var(--main_width)
    width 100%

		^[0]--full-width & {
			max-width: none
		}
  }

  &__header {
    display flex
    align-items center
    width 100%
    margin-bottom 24px
    justify-content space-between
    +below(860px) {
      display grid
      grid-gap 16px
      justify-content stretch

      .btn {
        width 100%
        justify-content center
				border: 1px solid var(--blue-300)
      }
    }

		.btn {
			font-size 1rem
		}
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0
		+above(1025px) {
			font-size: 1.875rem
			font-weight: 500;
			line-height: 1.38;
		}
  }
}
</style>
