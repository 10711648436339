<template>
  <div class="product" :class="{ 'product--modal': isModal }">
    <ProductGalleryComponent
      :items="images"
      :head_img="head_img"
      :default-alt="data.title"
      class="product__gallery-top"
      :is-modal="false"
      v-if="!isModal"
    />
    <div class="product__header">
      <div class="product__main-header">
        <h3 v-if="isModal" class="product__title">
          <template v-if="data.brand && data.brand.title">{{ data.brand.title }} / </template>
          {{ data.title }}
        </h3>
        <h1 v-else class="product__title">
          <template v-if="data.brand && data.brand.title">{{ data.brand.title }} / </template>
          {{ data.title }}
        </h1>
        <button type="button" v-if="isModal" class="product__close" @click="$emit('close')">
          <RemixIconComponent category="System" name="close-line" />
        </button>
      </div>
      <div class="product__small-header">
        <span v-if="active_set.articul" class="product__element">
          <span>Артикул:</span>
          <span>{{ active_set.articul }}</span>
        </span>
        <div class="product__reviews" v-if="data.reviews">
          <div class="product__reviews-stars">
            <RemixIconComponent
              class="product__reviews-star"
              :class="{ 'product__reviews-star--filled': averageReviewsMark >= i }"
              category="System"
              name="star-fill"
              v-for="i in 5"
              :key="i"
            />
          </div>
          <router-link
            :to="{ name: 'product', params: { id: data.id }, hash: '#reviews' }"
            class="product__reviews-count"
          >
            {{ data.reviews.length }} {{ data.reviews.length | plural_word("отзыв", "отзыва", "отзывов") }}
          </router-link>
        </div>
        <span v-if="active_set.number_purchases" class="product__bought">
          Купили {{ active_set.number_purchases || 0 }} раз
        </span>
      </div>
    </div>
    <div class="product__content">
      <div class="product__content-left">
        <ProductGalleryComponent
          :items="images"
          :head_img="head_img"
          :default-alt="data.title"
          class="product__gallery"
          :is-modal="isModal"
        />
        <article
          v-if="!isModal && data.attribute_values && data.attribute_values.length"
          class="product-props__article"
        >
          <h3 class="product-props__title">Характеристики</h3>
          <ul class="product-props__list">
            <li
              v-for="(prop, i) in data.attribute_values.slice(
                0,
                showFullProps ? data.attribute_values.length : 9
              )"
              :key="i"
            >
              <span v-if="prop.attribute">{{ prop.attribute.title }}</span>
              <span v-if="prop.value">{{ prop.value.value }}</span>
            </li>
          </ul>
          <a
            v-if="data.attribute_values.length > 9"
            class="product-props__action"
            href="#"
            @click.prevent="showFullProps = !showFullProps"
          >
            <template v-if="showFullProps">Свернуть характеристики</template>
            <template v-else>Развернуть характеристики</template>
          </a>
        </article>
      </div>
      <div class="product__info">
        <div v-if="active_set.price" class="product__prices">
          <span class="product__price">
            {{ active_set.price | share_price(active_set) | price_from_dollar($store.state) }}
          </span>
          <span v-if="active_set.join_share && active_set.join_share.share" class="product__old-price">
            {{ active_set.price | price_from_dollar($store.state) }}
          </span>
        </div>
        <div v-if="productComposition" class="product__element">
          <span>Состав:</span>
          <span>
            {{ productComposition }}
          </span>
        </div>
        <div v-if="data.product_sets && data.product_sets.length" class="product__attributes">
          <template v-for="(key, index) in Object.keys(attributes)">
            <div :key="index" class="product__attribute-container" v-if="attributes[key].values.length">
              <span>{{ attributes[key].title }}:</span>
              <label
                v-if="key === wholesaleVariation"
                class="product__attribute"
                :class="{
                  'product__attribute--active': wholesaleActive,
                }"
              >
                <input
                  type="checkbox"
                  v-show="false"
                  :checked="wholesaleActive"
                  @change="wholesaleActive = !wholesaleActive"
                />
                <span class="product__attribute-title">{{ getWholesaleTitle() }}</span>
              </label>
              <div v-else class="product__attributes-list">
                <template v-for="(item, i) in attributes[key].values">
                  <label
                    v-if="item.value"
                    :key="i"
                    class="product__attribute"
                    :class="{
                      'product__attribute--active': isAttributeChecked(key, item),
                      'product__attribute--disable':
                        isAttributeDisable(key, item) && sellingType !== types.WHOLESALE,
                    }"
                  >
                    <input
                      type="checkbox"
                      v-show="false"
                      :checked="isAttributeChecked(key, item)"
                      @change="changeSet(key, item)"
                    />
                    <span class="product__attribute-title">{{ item.value }}</span>
                  </label>
                </template>
              </div>
            </div>
          </template>
        </div>
        <a
          v-if="data.size_table"
          href="#"
          @click.prevent="showSizesTableModal"
          class="product__sizes-table-link"
        >
          Таблица размеров
        </a>
        <article v-if="!isModal" class="product-props__article">
          <h3 class="product-props__title">Описание</h3>
          <template v-if="data.description">
            <p class="product-props__description">
              {{ showFullDescription ? data.description : data.description.substr(0, 175) }}
            </p>
            <a
              v-if="data.description.length > 175"
              class="product-props__action"
              href="#"
              @click.prevent="showFullDescription = !showFullDescription"
            >
              <template v-if="showFullDescription">Свернуть описание</template>
              <template v-else>Развернуть описание</template>
            </a>
          </template>
          <p v-else class="product-props__description">Описание товара отсутствует</p>
        </article>
        <div class="product__buttons">
          <button
            type="button"
            :class="inCart ? 'btn--green' : 'btn--main'"
            class="btn btn--lg product__buttons-n1"
            @click="cartAdd"
          >
            <span v-if="!inCart"> Добавить в корзину </span>
            <span v-else>Перейти в корзину</span>
          </button>
          <button @click="cartAdd(true)" type="button" class="btn btn--lg btn--main-inv product__buttons-n2">
            Купить сейчас
          </button>
          <button
            :class="inFavorites ? 'btn--main' : 'btn--main-inv'"
            type="button"
            class="btn btn--lg product__buttons-n3"
            @click.prevent="addToFavorites"
          >
            <FileIconComponent v-if="loading" name="loading" />
            <RemixIconComponent v-else category="Health" name="heart-3-line" />
          </button>
        </div>
        <!--        <div class="product__element" v-if="isModal">-->
        <!--          <span>Ключевые слова:</span>-->
        <!--          <span>рубашка, клетка, девушка</span>-->
        <!--        </div>-->
        <div class="product__element" v-if="!isModal">
          <span>Доставка с момента заказа:</span>
          <span>45-60 дней</span>
        </div>
        <ShareNetworksComponent
          :is-modal="isModal"
          class="product-page__share-networks"
          :data="data"
          :originUrl="isModal ? `/product/${data.id}/${data.link}` : null"
          isProduct
        />
        <div class="product__seller" v-if="!isModal && data.shop && data.shop.title">
          <div class="product__seller-header">
            <div class="product__seller-title">Продавец</div>
            <router-link :to="{ name: 'shop', params: { id: data.shop.id } }" class="product__seller-name">
              <span>{{ data.shop.title }}</span>
              <RemixIconComponent category="System" name="information-line" />
            </router-link>
          </div>
          <div
            v-if="
              $options.filters.editor_has_text(data.shop.advantage) ||
              $options.filters.editor_has_text(data.shop.payment) ||
              $options.filters.editor_has_text(data.shop.delivery)
            "
            class="product__seller-list"
          >
            <div v-if="$options.filters.editor_has_text(data.shop.advantage)" class="product__seller-item">
              <span class="product__seller-item-title">Наши преимущества</span>
              <EditorJSComponent :text="JSON.parse(data.shop.advantage)" />
            </div>
            <div v-if="$options.filters.editor_has_text(data.shop.payment)" class="product__seller-item">
              <span class="product__seller-item-title">Оплата</span>
              <EditorJSComponent :text="JSON.parse(data.shop.payment)" />
            </div>
            <div v-if="$options.filters.editor_has_text(data.shop.delivery)" class="product__seller-item">
              <span class="product__seller-item-title">Доставка</span>
              <EditorJSComponent :text="JSON.parse(data.shop.delivery)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      href="#"
      @click.prevent="handleMoreBtnClick"
      v-if="isModal"
      class="product__go-to-full btn btn--lg btn--main-inv"
    >
      Больше информации о товаре
    </a>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ProductGalleryComponent from "components/product/ProductGalleryComponent.vue";
import SizesTableModal from "components/modals/components/SizesTableModal.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ShareNetworksComponent from "components/ShareNetworksComponent.vue";
// import moment from "moment";

export default {
  name: "ProductComponent",
  props: {
    data: Object,
    isModal: Boolean,
    vertical: Boolean,
  },
  data() {
    return {
      showFullDescription: false,
      showFullProps: false,
      loading: false,
      active_set: null,
      wholesaleActive: false,
      // deliveryDate: moment().locale("ru").add(15, "days").format("D MMM"),
      attributes: {
        variation_one: {
          id: null, // id атрибута
          title: null, // заголовок атрибута
          values: [], // значения атрибута
        },
        variation_two: {
          id: null,
          title: null,
          values: [],
        },
        variation_three: {
          id: null,
          title: null,
          values: [],
        },
      },
      selected_attribute_values: {
        variation_one: null,
        variation_two: null,
        variation_three: null,
      },
      attributesSliderOptions: {
        slidesPerView: "auto",
        spaceBetween: 8,
        pagination: false,
      },
    };
  },
  watch: {
    // выбранные значения атрибутов, на основании них находим соответствующую вариацию, если она есть
    selected_attribute_values: {
      handler() {
        if (this.sellingType === this.types.WHOLESALE) {
          this.active_set = this.data;
        } else {
          this.active_set =
            this.data.product_sets.find(
              (item) =>
                item.variation_one?.value.id === this.selected_attribute_values.variation_one?.id &&
                item.variation_two?.value.id === this.selected_attribute_values.variation_two?.id &&
                item.variation_three?.value.id === this.selected_attribute_values.variation_three?.id
            ) || this.data;
        }
      },
      deep: true,
    },
  },
  created() {
    // по умолчанию активной вариацией является родительский товар
    this.active_set = this.data;
    if (this.data.product_sets && this.data.product_sets.length) {
      // берем названия и id атрибутов первой вариации, например цвет, размер
      if (this.data.product_sets[0].variation_one && this.data.product_sets[0].variation_one.attribute) {
        this.attributes.variation_one.title = this.data.product_sets[0].variation_one.attribute.title;
        this.attributes.variation_one.id = this.data.product_sets[0].variation_one.attribute.id;
      }
      if (this.data.product_sets[0].variation_two && this.data.product_sets[0].variation_two.attribute) {
        this.attributes.variation_two.title = this.data.product_sets[0].variation_two.attribute.title;
        this.attributes.variation_two.id = this.data.product_sets[0].variation_two.attribute.id;
      }
      if (this.data.product_sets[0].variation_three && this.data.product_sets[0].variation_three.attribute) {
        this.attributes.variation_three.title = this.data.product_sets[0].variation_three.attribute.title;
        this.attributes.variation_three.id = this.data.product_sets[0].variation_three.attribute.id;
      }
      // проходимся по вариациям и собираем все значения атрибутов для каждого атрибута variation_...
      this.data.product_sets.forEach((item) => {
        if (
          item.variation_one &&
          item.variation_one.value &&
          !this.attributes.variation_one.values.find((v) => v.id === item.variation_one.value.id)
        ) {
          this.attributes.variation_one.values.push(item.variation_one.value);
        }
        if (
          item.variation_two &&
          item.variation_two.value &&
          !this.attributes.variation_two.values.find((v) => v.id === item.variation_two.value.id)
        ) {
          this.attributes.variation_two.values.push(item.variation_two.value);
        }
        if (
          item.variation_three &&
          item.variation_three.value &&
          !this.attributes.variation_three.values.find((v) => v.id === item.variation_three.value.id)
        ) {
          this.attributes.variation_three.values.push(item.variation_three.value);
        }
      });
      // если атрибут комплекта первый, то задаем, что комплект выбран(активен)
      if (this.wholesaleVariation === "variation_one") {
        this.wholesaleActive = true;
      } else {
        // иначе выбираем первый атрибут как активный
        this.selected_attribute_values.variation_one = this.attributes.variation_one.values[0];
      }
    }
  },
  computed: {
    images() {
      if (this.wholesaleSets && this.wholesaleSets.length) {
        let sets = [];
        this.wholesaleSets.forEach((item) => {
          if (item.set && item.set.images && item.set.images) {
            sets.push(...item.set.images);
          }
        });
        return sets;
      }
      return this.active_set.images || this.data.images;
    },
    head_img() {
      return this.active_set.head_img || this.data.head_img;
    },
    inCart() {
      // если каждый из коллекции есть в корзине
      if (this.sellingType === this.types.WHOLESALE) {
        return (
          !this.needVariationSelect() &&
          !this.wholesaleSets.filter(
            (item) => !this.$store.state.auth.cart.find((i) => i.item.id === item.set.id)
          ).length
        );
      }
      return this.active_set && !!this.$store.state.auth.cart.find((c) => c.item?.id === this.active_set?.id);
    },
    // средняя оценка продукта
    averageReviewsMark() {
      if (this.data && this.data.reviews && this.data.reviews.length) {
        let length = this.data.reviews.length;
        return Math.floor(this.data.reviews.map((r) => parseInt(r.mark)).reduce((a, b) => a + b, 0) / length);
      }
      return 0;
    },
    inFavorites() {
      return this.$store.state.auth.favorites.includes(this.data.id);
    },
    isRegistered() {
      return !!this.$store.state.apollo_token;
    },
    // тип продажи товара, комплект, шаг, розница или не продается
    sellingType() {
      if (
        this.data.in_wholesale &&
        this.data.in_wholesale &&
        this.data.wholesale &&
        this.data.wholesale.length
      ) {
        return this.types.WHOLESALE;
      }
      if (this.data.in_wholesale && this.data.step && this.data.step !== 1) {
        return this.types.STEP;
      }
      // if (this.data.retail) {
      //   return this.types.RETAIL;
      // }
      // return this.types.NOT_SELLING;
      return this.types.RETAIL;
    },
    types() {
      return this.$store.state._types;
    },
    // атрибут с которым связан комплект, например размер
    wholesaleVariation() {
      if (this.sellingType === this.types.WHOLESALE) {
        return Object.keys(this.attributes).find(
          (key) => this.data.wholesale[0].attribute_id === this.attributes[key].id
        );
      }
      return null;
    },
    // вариации в комплекте
    wholesaleSets() {
      let sets = [];
      if (this.sellingType === this.types.WHOLESALE) {
        this.data.wholesale.forEach((item) => {
          let set = this.findSet(this.wholesaleVariation, item.attribute_value);
          if (set) {
            sets.push({
              set,
              count: item.count,
            });
          }
        });
      }
      return sets;
    },
    items() {
      // если комплект, то возвращаем вариации в комплекте
      switch (this.sellingType) {
        case this.types.WHOLESALE: {
          return this.wholesaleSets.map((item) => {
            return {
              count: item.count,
              item: item.set,
            };
          });
        }
        // если шаг, то этот товар с шагом
        case this.types.STEP: {
          return [
            {
              count: this.active_set.step,
              item: this.active_set,
            },
          ];
        }
        // по умолчанию сам товар в количестве 1
        default: {
          return [
            {
              count: 1,
              item: this.active_set,
            },
          ];
        }
      }
    },
    productComposition() {
      if (this.data && this.data.compositions && this.data.compositions.length) {
        return this.data.compositions.map((i) => `${i.composition.title} ${i.percent}`).join(", ");
      }
      return null;
    },
  },
  methods: {
    // проверка на наличие сетов комплекта по значению атрибута
    checkWholesaleSets(value) {
      let sets = [];
      if (this.sellingType === this.types.WHOLESALE) {
        this.data.wholesale.forEach((item) => {
          let set = this.findSet(this.wholesaleVariation, value);
          if (set) {
            sets.push({
              set,
              count: item.count,
            });
          }
        });
      }
      return sets;
    },
    // получение заголовка для комплекта
    getWholesaleTitle() {
      return this.data.wholesale.map((item) => `${item.attribute_value.value}-${item.count}шт`).join("/");
    },
    // добавление в корзину
    cartAdd(redirect = false) {
      // если вариации есть, но ни одна не выбрана
      if (this.needVariationSelect()) {
        this.$notify({
          title: "Внимание",
          text: "Выберите, пожалуйста, модель товара",
          duration: 3000,
          speed: 200,
          type: "warn",
        });
      } else {
        // если такого товара нет в корзине, то добавляем
        if (!this.inCart) {
          this.$store.state.auth.cart.unshift(
            ...this.items.filter(
              (item) => !this.$store.state.auth.cart.find((i) => i.item?.id === item.item?.id)
            )
          );
          if (redirect === true) {
            this.$router.push({ name: "cart" }).then(() => {
              this.$emit("close");
            });
          }
        } else {
          this.$router.push({ name: "cart" }).then(() => {
            this.$emit("close");
          });
        }
        if (this.isRegistered && !this.loading) {
          this.$store
            .dispatch("CART_ADD_ITEMS", {
              apollo: this.$apollo,
              variables: {
                items: this.items.map((i) => {
                  return {
                    id: i.item.id,
                    count: i.count,
                  };
                }),
              },
            })
            .then(async () => {
              // удаляем из избранных
              this.addToFavorites(false);
              if (redirect === true) {
                this.$router.push({ name: "cart" }).then(() => {
                  this.$emit("close");
                });
              }
              this.loading = false;
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    },
    changeSet(variation, val) {
      if (
        this.selected_attribute_values[variation] &&
        this.selected_attribute_values[variation].id === val.id
      ) {
        this.selected_attribute_values[variation] = null;
      } else {
        this.selected_attribute_values[variation] = val;
      }
    },
    findSet(variation, val) {
      let selected_attribute_values = JSON.parse(JSON.stringify(this.selected_attribute_values));
      selected_attribute_values[variation] = val;

      return this.data.product_sets.find(
        (s) =>
          s.variation_one?.value.id === selected_attribute_values.variation_one?.id &&
          s.variation_two?.value.id === selected_attribute_values.variation_two?.id &&
          s.variation_three?.value.id === selected_attribute_values.variation_three?.id
      );
    },
    isAttributeChecked(variation, val) {
      if (this.selected_attribute_values[variation]) {
        return this.selected_attribute_values[variation].id === val.id;
      }
      return false;
    },
    isAttributeDisable(variation, val) {
      let selected_attribute_values = JSON.parse(JSON.stringify(this.selected_attribute_values));
      selected_attribute_values[variation] = val;
      if (this.sellingType === this.types.WHOLESALE) {
        return !this.checkWholesaleSets(val).length;
      } else {
        let sets = this.data.product_sets;
        if (selected_attribute_values.variation_one) {
          sets = sets.filter(
            (item) =>
              item.variation_one && item.variation_one.value.id === selected_attribute_values.variation_one.id
          );
        }
        if (selected_attribute_values.variation_two) {
          sets = sets.filter(
            (item) =>
              item.variation_two && item.variation_two.value.id === selected_attribute_values.variation_two.id
          );
        }
        if (selected_attribute_values.variation_three) {
          sets = sets.filter(
            (item) =>
              item.variation_three &&
              item.variation_three.value.id === selected_attribute_values.variation_three.id
          );
        }
        return !sets.length;
      }
    },
    needVariationSelect() {
      if (this.data.product_sets && this.data.product_sets.length) {
        if (this.sellingType === this.types.WHOLESALE) {
          return !(this.wholesaleActive && this.wholesaleSets && this.wholesaleSets.length);
        } else {
          return !this.data.product_sets.find((item) => item.id === this.active_set.id);
        }
      }
      return false;
    },
    async addToFavorites(showLogin = true) {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites.indexOf(this.data.id);
        if (index !== -1) {
          this.$store.state.auth.favorites.splice(index, 1);
        } else {
          this.$store.state.auth.favorites.push(this.data.id);
        }
        await this.$store.dispatch("FAVORITE_CREATE_OR_DELETE", {
          apollo: this.$apollo,
          variables: {
            item_id: this.data.id,
          },
        });
      } else {
        if (showLogin) {
          this.showLoginModal();
        }
      }
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
    handleMoreBtnClick() {
      this.$router
        .push({ name: "product", params: { id: this.data.id, link: this.data.link } })
        .then(() => this.$emit("close"));
    },
    showSizesTableModal() {
      this.$store.state._modals.push({
        component: SizesTableModal,
        options: {
          size_table: this.data.size_table || {},
        },
      });
    },
  },
  components: {
    ShareNetworksComponent,
    EditorJSComponent,
    FileIconComponent,
    ProductGalleryComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
padding = 24px
mobile_padding = 15px
.product {
  z-index: 1;
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  gap 32px
  max-width var(--main_width)
  width 100%
  +below(860px) {
    gap 15px
  }

  &--modal {
    padding padding padding 0
    background var(--white)
    border-radius var(--big_radius)
    max-width 1148px
    margin 0 15px
    +below(860px) {
      padding mobile_padding mobile_padding 0
    }
  }

  &__gallery-top {
    width 100%
    +above(861px) {
      display none
    }

    .product-gallery__image {
      height 334px
    }
  }

  &__go-to-full {
    width 'calc(100% + (%s * 2))' % padding
    margin '0 -%s 0 -%s' % (padding padding)
    justify-content center

    &:active {
      transform scale(1)
    }

    +below(860px) {
      width 'calc(100% + (%s * 2))' % mobile_padding
      margin '0 -%s 0 -%s' % (mobile_padding mobile_padding)
    }
  }

  &__header {
    display flex
    flex-direction column
    align-items stretch
    justify-content stretch
    width 100%
    gap 8px
  }

  &__main-header {
    display: flex;
    align-items center
    justify-content space-between
    width 100%
  }

  h1&__title {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    margin 0
    display: flex;
    align-items: center;
    color: var(--body-color);
    +below(860px) {
      font-size 1.125em
      line-height: 28px;
    }
  }

  h3&__title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    margin 0
    color: var(--body-color);
    +below(860px) {
      font-size 1.125em
      line-height: 28px;
    }
  }

  &__close {
    width 40px
    height 40px
    background none
    padding 0
    border-radius var(--small_radius)
    border none
    transition var(--transition)
    display inline-flex
    align-items center
    justify-content center
    cursor pointer

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--body-color-muted)
      }
    }

    &:hover {
      background var(--blue-200)
    }
  }


  &__small-header {
    display flex
    align-items center
    justify-content flex-start
    gap 32px
    width 100%
    +below(860px) {
      flex-direction column
      gap 12px
      align-items: flex-start;
    }
  }

  &__reviews {
    display inline-flex
    align-items center
    justify-content flex-start
    gap 8px
  }

  &__reviews-stars {
    display inline-flex
    align-items flex-start
    justify-content flex-start
  }

  &__reviews-star {
    width 20px
    height 20px

    &:not(&--filled) {
      svg path {
        fill transparent
        stroke var(--yellow)
      }
    }

    &--filled {
      svg path {
        fill var(--yellow)
      }
    }
  }

  &__reviews-count {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-300);
    text-decoration underline
  }

  &__element {
    display inline-flex
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    align-items: center;
    gap 4px
    flex-wrap wrap

    span:first-child {
      color: var(--gray-300);
    }

    span:last-child {
      color: var(--gray-600);
    }
  }

  &__bought {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-300);
  }

  &--modal &__content {
    grid-template-columns 6fr 4fr
    +below(860px) {
      grid-template-columns 1fr
    }
  }

  &__content {
    display grid
    align-items start
    justify-content start
    grid-template-columns minmax(550px, 1fr) 1fr
    grid-gap 32px
    width: 100%;
    +below(1050px) {
      grid-template-columns 1fr
      grid-gap 15px
    }

		&-left {
			display flex
			flex-direction column
			gap 20px
		}
  }

  &--modal &__gallery {
    display flex
    justify-content center
    max-height: 510px;
    height: 100%;
    +below(1024px) {
      max-height 430px
    }
  }

  &:not(&--modal) &__gallery {
    +below(860px) {
      display none
    }
  }

  &--modal &__gallery .param-slider {
    height 450px
    +below(860px) {
      height 120px
    }
  }

  &--modal &__gallery .product-gallery__image {
    max-width 526px
    height 510px
    +below(1024px) {
      height 430px
    }
    +below(860px) {
      max-width 100%
      height 374px
    }
  }

  &--modal &__info {
    max-width: 432px;
    +below(1024px) {
      max-width 380px
    }
    +below(860px) {
      max-width 100%
      gap 15px
    }
  }

  &__attributes {
    width 100%
    display grid
    grid-gap 8px
  }

  &__info {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 24px
    max-width 100%
    width: 100%;
  }

  &__prices {
    display flex
    align-items flex-start
    justify-content flex-start
    gap 12px
  }

  &__price {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: var(--body-color);
  }

  &__old-price {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: var(--gray-200);
  }

  &__sizes-table-link {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: var(--gray-300);
  }

  &__buttons {
    display flex
    align-items center
    justify-content flex-start
    gap 8px
    flex-wrap wrap
    +below(860px) {
      width: 100%;
      &-n1 {
        order 1
        flex-grow: 1;
        justify-content center
      }
      &-n2 {
        order 3
        width 100%
        justify-content center
      }
      &-n3 {
        order 2
      }
    }
  }

  &__attribute-container {
    display flex
    flex-direction column
    align-items flex-start
    gap 4px
  }

  &__attributes-list {
    display flex
    flex-wrap wrap
    gap: 8px
  }

  &__attribute {
    border: 1px solid var(--blue-500);
    box-sizing: border-box;
    border-radius var(--small_radius)
    padding 8px
    display flex
    align-items center
    justify-content center
    text-align center
    transition var(--transition)
    cursor pointer
    min-width 40px

    &:hover {
      border: 1px solid var(--blue-900);
      background var(--blue-200)
    }

    &--active
    &--active:hover {
      border-color var(--accent)
    }

    &--disable {
      opacity 0.7
      pointer-events none
      background var(--blue-400)
    }
  }

  &__attribute-title {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--body-color-muted);
  }

  &__attribute-subtitle {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-200);
  }

  &__color {
    cursor pointer
    border: 2px solid transparent;
    border-radius var(--small_radius)
    height 120px
    width 86px
    overflow hidden
    transition var(--transition)

    &:not(&--active):hover {
      border-color var(--orange-200)
    }

    &--active {
      border-color var(--accent)
    }

    &--small {
      +below(860px) {
        height 86px
      }
    }

    img {
      width 100%
      height 100%
      object-fit contain
      object-position center
    }
  }

  &__color-slider {
    display flex
    flex-direction column
    width: 100%;
    gap 12px
    align-items flex-start
    justify-content flex-start
  }

  &__seller {
    display grid
    grid-gap 16px
    background: var(--blue-200);
    border-radius: var(--main_radius);
    padding 16px
    width 100%
  }

  &__seller-header {
    display flex
    align-items center
    justify-content flex-start
    gap 16px
    flex-wrap wrap
  }

  &__seller-title {
    padding 12px
    font-weight: 500;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: var(--gray-600);
    background: var(--blue-100);
    border-radius: var(--small_radius);
  }

  &__seller-name {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content flex-start
    color: var(--gray-700);
    gap 8px

    .icon {
      width 16px
      height 16px
      flex-shrink 0

      svg path {
        fill var(--blue-900)
      }
    }
  }

  &__seller-list {
    display flex
    align-items flex-start
    justify-content flex-start
    gap 24px
    flex-wrap wrap
    padding 0 8px 8px
  }

  &__seller-item {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 8px
  }

  &__seller-item-title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-700);
  }

  &__seller-item-list {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    flex-direction column
    align-items: flex-start;
    justify-content flex-start
    color: var(--gray-600);
  }
}
</style>
