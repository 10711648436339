<template>
  <router-link
    :to="{ name: 'catalog', params: { id: data.id }, query: { page_title: data.title } }"
    class="main-category"
  >
    <div class="main-category__img shadow">
      <ImgComponent :width="900" :height="1050" :head_img="data.head_img" />
    </div>
    <span class="main-category__title">{{ data.title }}</span>
  </router-link>
</template>

<script>
export default {
  name: "MainCategoryItem",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.main-category {
  background: var(--white);
  border-radius: var(--main_radius);
  display inline-flex
  flex-direction column
  align-items center
  justify-content flex-start
  gap 16px
	height: auto
  width 100%

  &:hover {
    border-color var(--main)
  }

  &__img {
		position: relative
    ratio(420, 620)
		border-radius: var(--main_radius);
		overflow: hidden

		&:hover img {
			scale 1.1
		}

    img {
      width 100%
      height 100%
      max-height 100%
      object-fit cover
      object-position center
    }
  }

  &__title {
		position: absolute
		bottom: 22%
		z-index: 1
    width 100%
    display: flex;
    align-items: center;
    justify-content: center;
		font-size: 1.5rem
		font-weight: 400;
		line-height: 1.33
		text-align: center;
		color: var(--white)
		pointer-events: none
		+below(1200px) {
			font-size: 1.25rem
		}
	}
}
</style>
