<template>
  <router-link
    :to="{
      name: 'catalog',
      query: { share_id: data.id, in_share: true, parent: true, page_title: data.title },
    }"
    class="main-slide"
    :class="{ 'main-slide--full-width': fullWidth }"
    v-if="data"
  >
    <div class="main-slide__image-container shadow">
      <ImgComponent
        v-if="$store.state._window_size > 860"
        class="main-slide__image"
        :head_img="data.head_img"
        :loader="false"
      />
      <ImgComponent
        v-else
        class="main-slide__image-mobile"
        :head_img="data.head_img_mobile || data.head_img"
        :loader="false"
      />
    </div>
    <div class="main-slide__content">
      <div class="container">
        <div class="main-slide__content-inner">
          <div class="main-slide__content-info">
            <h2 class="main-slide__title" :title="data.title">{{ data.title }}</h2>
            <p class="main-slide__description">{{ data.subtitle }}</p>
          </div>
          <button class="btn btn--lg btn--green">Перейти в каталог</button>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "SlideComponent",
  props: {
    data: Object,
    fullWidth: Boolean,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/textcut.styl"
.main-slide {
  background: var(--white);
  border-radius: var(--main_radius);
  height 333px
  position relative
  overflow hidden
  display inline-flex
  align-items flex-start
  justify-content flex-start
  min-width 450px
  width 100%
  +below(860px) {
    height auto
    min-height 300px
    min-width auto
  }
  +below(540px) {
    min-height auto
  }

	&:hover {
		opacity 0.9
	}

	&--full-width {
		border-radius: 0
		//height 900px
		height auto
	}

  &__content {
    absolute left right bottom
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-end
		gap: 10px
    z-index 1
    padding 15px
		+above(1201px) {
			padding-bottom 70px
		}

		&-inner {
			display flex
			align-items flex-end
			gap: 30px
			justify-content space-between
		}

		&-info {
			display flex
			flex-direction column
			gap: 30px
			+below(1200px) {
				gap: 10px
			}
		}

		.btn {
			flex-shrink 0
			+below(860px) {
				display: none
			}
		}
  }

  &__title {
		font-weight: 500;
    font-size: 3.25rem
    line-height: 1.2;
    display: flex;
    align-items: center;
    color: var(--white);
    margin 0;
		textcut(2);
		+below(1200px) {
			font-size: 2.25rem;
		}
		+below(860px) {
      font-size: 1.5rem;
    }
		+below(540px) {
			font-size: 1.25rem;
		}
  }

  &__description {
    font-size: 1.5rem;
    line-height: 1.2;
    display: flex;
    align-items: center;
    color: var(--white);
    margin 0
		textcut(2);
		+below(1200px) {
			font-size: 1.25rem;
		}
    +below(860px) {
      font-size: 1rem;
    }
		+below(540px) {
			font-size: 0.875rem;
		}
  }

  &__image {
    width 100%
    height 100%
    object-fit cover
    object-position center
		//filter: brightness(0.8)
    +below(860px) {
      display none
    }

    &-container {
			position: relative
      width 100%
      height 100%
      +below(860px) {
        ratio(450, 300)
      }

			^[0]--full-width & {
				ratio(1920, 780)
			}
    }

    &-mobile {
			//filter: brightness(0.9)
      +above(861px) {
        display none
      }
    }
  }
}
</style>
