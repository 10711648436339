<template>
  <div class="share-networks">
    <span class="share-networks__title">Поделиться:</span>
    <ul class="share-networks__list">
      <li v-for="(item, i) in socials" :key="i">
        <ShareNetwork :url="shareUrl" :network="item.network" :title="data.title">
          <span class="share-networks__item">
            <span :title="item.title">
              <FileIconComponent :name="item.icon" />
            </span>
          </span>
        </ShareNetwork>
      </li>
      <li v-if="!isModal">
        <a @click.prevent="copyUrl" href="#">
          <span class="share-networks__item">
            <span title="Скопировать ссылку">
              <FileIconComponent v-if="done" name="tick" />
              <FileIconComponent v-else name="copy" />
            </span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "ShareNetworksComponent",
  components: { FileIconComponent },
  props: {
    data: Object,
    isModal: Boolean,
    originUrl: String, // ссылка на страницу, если нужно чтобы ссылалось на нее, вместо текущей
  },
  data() {
    return {
      done: false,
      socials: [
        {
          title: "ВКонтакте",
          network: "vk",
          icon: "vk",
        },
        {
          title: "Telegram",
          network: "telegram",
          icon: "telegram",
        },
        {
          title: "Whatsapp",
          network: "whatsapp",
          icon: "whatsapp",
        },
        {
          title: "Одноклассники",
          network: "odnoklassniki",
          icon: "ok",
        },
      ],
    };
  },
  computed: {
    // ссылка которой мы делимся, если это модалка, то ссылка на внутреннюю страницу
    // иначе просто текущая ссылка
    shareUrl() {
      return this.$store.state._env.MEDIA_ENDPOINT + (this.originUrl || this.$route.fullPath);
    },
  },
  methods: {
    // копирование текущей ссылки
    async copyUrl() {
      try {
        await navigator.clipboard.writeText(location.href);
      } catch (err) {
        let inp = document.createElement("input");
        inp.value = location.href;
        document.body.appendChild(inp);
        inp.select();
        document.execCommand("copy");
        document.body.removeChild(inp);
      }
      this.done = true;
      setTimeout(() => {
        this.done = false;
      }, 1000);
    },
  },
};
</script>

<style lang="stylus">
.share-networks {
  display flex
  align-items center
  justify-content center
  gap: 5px

  &__title {
    display flex
  }

  &__list {
    display flex
    flex-direction row
    gap: 5px

    li {
      display flex
      align-items center

      a {
        width 100%
      }
    }
  }

  &__item {
    display flex
    align-items center
    gap: 5px
    border-radius 4px
    overflow hidden

    &:hover {
      .file-icon svg path {
        fill var(--brown-light)
      }
    }

    > span {
      display flex
      white-space nowrap
      flex-shrink 0
    }

    .icon,
    .file-icon {
      width: 24px
      height: 24px
    }

    .file-icon {
      svg path {
        fill var(--dark)
        transition var(--transition)
      }
    }
  }
}
</style>
