<template>
  <div class="home-discounts">
    <router-link
      :to="{ name: 'share', params: { id: item.id } }"
      v-for="item in items"
      :key="item.id"
      class="home-discounts__item shadow"
    >
      <ImgComponent class="home-discounts__item-img" :head_img="item.head_img" :default-alt="item.title" />
      <div class="home-discounts__item-cover">
        <span class="home-discounts__item-title">{{ item.title }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HomeDiscountsComponent",
  props: {
    items: Array,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.home-discounts {
	column-count: 3;
	column-gap: 24px;

	&__item {
		overflow hidden
		border-radius 16px
		margin-bottom 24px
		cursor: pointer;
		display: flex

		&:hover &-img {
			scale: 1.1
		}

		&:nth-child(1),
		&:nth-child(4),
		&:nth-child(5) {
			ratio(420, 350)

			^[0]__item-title {
				margin-bottom: 15%
			}
		}

		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(6) {
			ratio(420, 250)

			^[0]__item-title {
				margin-bottom: 10%
			}
		}

		&-cover {
			absolute left right top bottom
			z-index: 1;
			color: var(--white)
			padding: 10px
			display flex
			justify-content center
			align-items flex-end
			text-align center
		}

		&-title {
			font-size: 1.125rem
			line-height: 1.3333;
		}
	}
}
</style>
