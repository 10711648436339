<template>
  <div class="default-modal sizes-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">{{ this.size_table.title || "Таблица размеров" }}</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="System" name="close-line" />
      </button>
    </div>
    <div v-if="size_table.sizes && size_table.sizes.length" class="default-modal__body sizes-modal__body">
      <table class="table">
        <thead>
          <tr>
            <th>Размер</th>
            <th v-for="(item, i) in size_table.sizes[0].size_values" :key="i">
              {{ item.size_table_header.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in size_table.sizes" :key="i">
            <td>{{ item.attribute_value.value }}</td>
            <td v-for="(val, ind) in item.size_values" :key="ind">{{ val.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "SizesTableModal",
  props: {
    size_table: Object,
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.sizes-modal {
  &__body {
    overflow-x auto
  }
}
</style>
