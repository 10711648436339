<template>
  <div
    class="product-gallery"
    :class="{ 'product-gallery--controls': items && items.length && items.length > 1 }"
  >
    <ProductParamSlider
      v-if="items && items.length"
      v-slot="{ item }"
      :items="items"
      :slider-options="sliderOptions"
      ref="params"
    >
      <div
        @mouseenter="active = item.id"
        @click="active = item.id"
        class="product__color"
        :class="{ 'product__color--active': active === item.id }"
      >
        <ImgComponent :head_img="item" width="400" height="400" />
      </div>
    </ProductParamSlider>
    <div @click="showImagesModal" class="product-gallery__image">
      <button @click="prevImage" class="product-gallery__btn product-gallery__btn-prev btn">
        <RemixIconComponent category="System" name="arrow-left-line" />
      </button>
      <button @click="nextImage" class="product-gallery__btn product-gallery__btn-next btn">
        <RemixIconComponent category="System" name="arrow-right-line" />
      </button>
      <ImgZoomComponent :head_img="activeItem" width="790" :height="isModal ? 765 : 1190">
        <ImgComponent
          :head_img="activeItem"
          width="790"
          :height="isModal ? 765 : 1190"
          :default-alt="defaultAlt"
        />
      </ImgZoomComponent>
    </div>
  </div>
</template>

<script>
import ProductParamSlider from "components/product/ProductParamSlider.vue";
import ImgZoomComponent from "components/ImgZoomComponent.vue";
import ImagesGalleryModal from "components/modals/components/ImagesGalleryModal.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "ProductGalleryComponent",
  props: {
    defaultAlt: String,
    items: {
      type: Array,
      default: () => [],
    },
    head_img: Object,
    firstActiveItem: Object,
    isModal: Boolean, // модалка с предварительным просмотром товара
    isImagesModal: Boolean, // модалка для просмотра фотографий товара
  },
  data() {
    return {
      active: null,
      sliderOptions: {
        direction: "horizontal",
        slidesPerView: "auto",
        spaceBetween: 4,
        pagination: false,
        clickable: false,
        slideToClickedSlide: true,
        breakpoints: {
          860: {
            direction: "vertical",
            slidesPerView: this.isModal ? 3 : 5,
          },
        },
      },
    };
  },
  computed: {
    activeItem() {
      return this.items.find((i) => i.id === this.active) || this.head_img;
    },
  },
  created() {
    if (this.firstActiveItem) {
      this.active = this.firstActiveItem?.id;
    } else if (this.items && this.items.length) {
      this.active = this.items[0].id;
    }
  },
  mounted() {
    if (this.isImagesModal) {
      document.addEventListener("keydown", this.handleKeyDown);
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", () => {});
  },
  watch: {
    items() {
      if (this.items && this.items.length) {
        this.active = this.items[0].id;
      }
    },
  },
  methods: {
    handleKeyDown(e) {
      if ([37, 38].includes(e.keyCode)) {
        this.prevImage();
      }
      if ([39, 40].includes(e.keyCode)) {
        this.nextImage();
      }
    },
    nextImage() {
      let currentIndex = this.items.findIndex((item) => item.id === this.active);
      // если есть текущий элемент и он не последний
      if (![-1, this.items.length - 1].includes(currentIndex) && this.$refs.params) {
        this.active = this.items[currentIndex + 1]?.id;
        this.$refs.params.slideNext();
      }
    },
    prevImage() {
      let currentIndex = this.items.findIndex((item) => item.id === this.active);
      // если есть текущий элемент и он не первый
      if (![-1, 0].includes(currentIndex) && this.$refs.params) {
        this.active = this.items[currentIndex - 1]?.id;
        this.$refs.params.slidePrev();
      }
    },
    showImagesModal() {
      if (!this.isModal && !this.isImagesModal) {
        this.$store.state._modals.push({
          component: ImagesGalleryModal,
          options: {
            images: this.items,
            head_img: this.head_img,
            defaultAlt: this.defaultAlt,
            activeItem: this.activeItem,
          },
          fullHeight: true,
        });
      }
    },
  },
  components: { RemixIconComponent, ImgZoomComponent, ProductParamSlider },
};
</script>

<style lang="stylus">
.product-gallery {
  display flex
  gap 24px
  +below(860px) {
    gap 0
    flex-direction column-reverse
  }

  .param-slider {
    //margin auto 0
    width 86px
    height 696px
    flex-shrink: 0;
    +below(860px) {
      width 100%
      height 120px
    }
  }

  &__image {
    height auto
    max-height 700px
    max-width 100%
    width 100%
    border-radius: var(--main_radius);
    display flex
    justify-content center
    align-items center
    overflow hidden
    margin-bottom 14px
    +above(861px) {
      min-height 350px
    }
    +below(540px) {
      margin-bottom: 0
    }

    img {
      width 100%
      height 100%
      object-fit contain
      object-position center
    }
  }

  &__btn {
    display none
  }
}
</style>
